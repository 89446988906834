import React from 'react';
import styled from 'styled-components';

import { pageHeroTypes } from '../types/propTypes';
import mq from '../style/mq';
import { Container, Inner } from './Elements';
import { font, colors } from '../consts/style';

const Wrapper = styled.div`
  background: ${props => props.bg};
  height: 60rem;
  position: relative;
  overflow: hidden;
  ${Container} {
    position: relative;
  }
  ${Inner} {
    display: flex;
    align-items: center;
    height: 100%;
  }
  ${mq.tabletSmall`
    height: 43rem;
  `}
`;
const Content = styled.div`
  width: 50%;
  position: relative;
  z-index: 10;
  h1 {
    color: ${props => (props.headingColor ? props.headingColor : colors.dark)};
    ${font.h1};
  }
  p {
    color: ${props => (props.bodyColor ? props.bodyColor : colors.dark)};
    ${font.p};
  }
`;

const HeroImagery = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 75%;
  z-index: 0;
  pointer-events: none;
  transform: translateX(16%);
  ${mq.desktop`
    width: 90%;
    transform: translateX(20%);
  `}
  ${mq.tabletSmall`
    width: 140%;
    transform: translateX(46%);
  `}
`;

const GIF = styled.img`
  width: 100%;
  max-width: 100%;
`;

export default function PageHero({
  bg,
  heading,
  body,
  gif,
  headingColor,
  bodyColor,
}) {
  return (
    <Wrapper bg={bg}>
      <Container>
        <Inner>
          <Content headingColor={headingColor} bodyColor={bodyColor}>
            <h1>{heading}</h1>
            <p>{body}</p>
          </Content>
          <HeroImagery>
            <GIF src={gif} />
          </HeroImagery>
        </Inner>
      </Container>
    </Wrapper>
  );
}

PageHero.propTypes = pageHeroTypes;
