import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import map from 'lodash/map';
import Img from 'gatsby-image';

import { colors, font } from '../consts/style';
import { Container, Inner } from './Elements';
import { infoBlocksTypes } from '../types/propTypes';
import mq from '../style/mq';
import moment from 'moment';

const timestamp = '[timestamp]';

const Wrapper = styled.div`
  background: ${colors.dark};
  ${Inner} {
    border-left: 0.8rem solid ${props => props.highlightColor};
    padding: 8rem 0 8rem 6rem;
    ${mq.tabletSmall`
      padding: 8rem 0 8rem 4rem;
    `}
  }
`;

const Block = styled.div`
  width: 100%;
  margin-bottom: 8rem;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
  h2 {
    ${font.h2};
  }
  .before,
  .with {
    h2 {
      ${font.h4};
      span {
        font-family: 'Teko';
        font-weight: bold;
      }
    }
  }
  .caret {
    width: 0;
    height: 0;
    border-top: 2rem solid transparent;
    border-bottom: 2rem solid transparent;
    border-left: 3.2rem solid ${props => props.highlightColor};
    position: absolute;
    top: -0.4rem;
    left: -6rem;
    ${mq.tabletSmall`
      border-top: 1.2rem solid transparent;
      border-bottom: 1.2rem solid transparent;
      border-left: 2.4rem solid ${props => props.highlightColor};
      top: -.4rem;
      left: -4rem;
    `}
  }
  .title {
    color: white;
  }
  .content {
    padding-left: 4rem;
    padding-top: 4rem;
    display: flex;
    ${mq.tabletSmall`
      flex-wrap: wrap;
      padding-left: 0;
    `}
    .image {
      margin-right: 6rem;
      width: 22rem;
      flex-shrink: 0;
      img {
        width: 100%;
        max-width: 100%;
      }
      ${mq.tabletSmall`
      width: 100%;
      max-width: 100%;
      text-align: center;
      margin-bottom: 2rem;
      img {
        width: 9rem;
      }
      `}
    }
    .body {
      flex-shrink: 1;
      ${mq.tabletSmall`
       padding-left: 0;
      `}
      .before {
        margin-bottom: 3.2rem;
      }
      h2 {
        color: ${props => props.highlightColor};
      }
      p,
      ul,
      li {
        color: white;
        ${font.p};
      }
      ul {
        list-style: none;
        padding-left: 2.4rem;
        li {
          position: relative;
          &:before {
            content: '';
            width: 0;
            height: 0;
            border-top: 0.8rem solid transparent;
            border-bottom: 0.8rem solid transparent;
            border-left: 1.2rem solid white;
            position: absolute;
            top: 0.2rem;
            left: -2.4rem;
            border-radius: 50%;
            ${mq.tabletSmall`
                border-top: 0.6rem solid transparent;
                border-bottom: 0.6rem solid transparent;
                border-left: 1rem solid white;
                top: 0.2rem;
            `}
          }
        }
      }
    }
  }
`;

export default function InfoBlocks({ blocks, highlightColor }) {
  const [ct, setTime] = useState('');

  useEffect(() => {
    setTime(moment().format('h:mm A'));
  }, []);

  function parseTime(val) {
    if (val.includes(timestamp))
      return '<h2>' + val.replace(timestamp, `<span>${ct}</span>`) + '</h2>';
    return `<h2>${val}</h2>`;
  }
  function renderBlocks() {
    return map(blocks, b => {
      return (
        <Block key={b.id} highlightColor={highlightColor}>
          <div className="caret" />
          <div className="title">
            <h2>{b.title}</h2>
          </div>
          <div className="content">
            <div className="image">
              <Img fluid={b.icon.fluid} />
            </div>
            <div className="body">
              <div className="before">
                <div
                  dangerouslySetInnerHTML={{ __html: parseTime(b.heading1) }}
                />

                <p>{b.body1}</p>
              </div>
              <div className="with">
                <div
                  dangerouslySetInnerHTML={{ __html: parseTime(b.heading2) }}
                />
                <div
                  className="html"
                  dangerouslySetInnerHTML={{ __html: b.body2 }}
                />
              </div>
            </div>
          </div>
        </Block>
      );
    });
  }
  return (
    <Wrapper highlightColor={highlightColor}>
      <Container>
        <Inner>{renderBlocks()}</Inner>
      </Container>
    </Wrapper>
  );
}

InfoBlocks.propTypes = infoBlocksTypes;
