import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SEO from '../components/SEO';
import { PageWrapper } from '../components/Elements';
import PageHero from '../components/PageHero';
import InfoBlocks from '../components/InfoBlocks';
import { colors } from '../consts/style';
import { blockColorSchemes } from './faq';
import FaqSection from '../components/FaqSection';

const explorationQuery = graphql`
  {
    datoCmsExplorationPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heroHeading
      heroBody
      heroGif {
        url
      }
      infoBlocks {
        id
        title
        heading1
        heading2
        body1
        body2
        icon {
          url
          fluid(maxWidth: 800, imgixParams: { fm: "png", auto: "compress" }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
      faqSection {
        title
        faqs {
          id
          question
          answer
          vimeoVideoId
          identifier
        }
      }
    }
  }
`;

export default function ExplorationPage() {
  const data = useStaticQuery(explorationQuery);
  const {
    seoMetaTags,
    heroHeading,
    heroBody,
    infoBlocks,
    heroGif,
    faqSection,
  } = data.datoCmsExplorationPage;

  const bg = colors.grey;

  const pageHeroProps = {
    bg,
    heading: heroHeading,
    body: heroBody,
    gif: heroGif.url,
    headingColor: colors.yellow,
    bodyColor: 'white',
  };

  const infoBlockProps = {
    highlightColor: colors.yellow,
    blocks: infoBlocks,
  };

  const faqProps = {
    title: faqSection.title,
    faqs: faqSection.faqs,
    colorScheme: blockColorSchemes[2],
    withCta: true,
  };
  return (
    <PageWrapper>
      <SEO meta={seoMetaTags} />
      <PageHero {...pageHeroProps} />
      <InfoBlocks {...infoBlockProps} />
      <FaqSection {...faqProps} />
    </PageWrapper>
  );
}
